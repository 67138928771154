<template>
  <div class="csn-registration-form-container csn-register-modal">
    <div class="box csn-registration-form-box">
      <div :class="classList.modalDialog">
        <div :class="classList.modalContent">
          <button :class="classList.closeButton" @click="closeModal">
            <CloseIcon />
          </button>
          <div :class="classList.modalHeader">
            <h5 :class="classList.modalTitle">
              {{ t('register_account') }}
            </h5>
          </div>
          <div :class="classList.modalBody">
            <RegistrationForm @closeModal="closeModal" />
            <div v-if="isGermanTranslation" class="csn-register-german-notice">
              <img
                src="@/assets/images/icons/general/Alert.svg"
                alt="warning"
              />
              <span>
                {{ t('german_notice') }}
                <a href="https://www.bzga.de/">BZgA.de</a>.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

import {
  MODAL_DIALOG,
  MODAL_CONTENT,
  MODAL_HEADER,
  MODAL_TITLE,
  MODAL_BODY,
  REGISTER_MODAL,
  CSN_MODAL_CLOSE_BUTTON,
  BTN_CLASS,
  Module,
  HIDE_MODAL,
  EventType,
} from '@/constants'
import { formMixin, modalMixin } from '@/mixins'

export default {
  name: REGISTER_MODAL,
  mixins: [formMixin, modalMixin],
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    RegistrationForm: () =>
      import('@/views/Registration/components/RegistrationForm'),
  },
  computed: {
    classList: () => ({
      modalDialog: MODAL_DIALOG,
      modalContent: MODAL_CONTENT,
      modalHeader: MODAL_HEADER,
      modalTitle: MODAL_TITLE,
      modalBody: MODAL_BODY,
      closeButton: [BTN_CLASS, CSN_MODAL_CLOSE_BUTTON],
    }),
    t() {
      return this.$createComponentTranslator(REGISTER_MODAL)
    },
    isGermanTranslation() {
      return window.i18n.locale.toLocaleUpperCase() === 'DE'
    },
  },
  methods: {
    ...mapMutations(Module.MODAL, [HIDE_MODAL]),
    closeModal(fn) {
      return this.$emit(EventType.CLOSE, fn)
    },
  },
}
</script>
